/* eslint-disable import/no-default-export */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Layout } from '../components/Layout';
import { Insurer } from '../components/Insurer/Insurer';
import { AlphabetFilter } from '../components/AlphabetFilter/AlphabetFilter';
import { insurers } from '../components/Insurer/insurersConfig';
import { HeroInsurersImage } from '../img/svg/HeroInsurersImage';

export default () => {
  const [filter, setFilter] = useState('');
  const [filteredInsurers, filterInsurers] = useState(insurers);
  const goBackToAll = () => {
    setFilter('');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (filter) {
      filterInsurers(insurers.filter(insurer => insurer.insurerName.toLowerCase().startsWith(filter)));
    } else {
      filterInsurers(insurers);
    }
  }, [filter]);

  return (
    <>
      <Helmet title="O Ubezpieczycielach | Blog" />
      <Layout>
        <section id="hero" className="section section--insurers-hero-image">
          <div className="wrapper">
            <HeroInsurersImage />
          </div>
        </section>
        <div className="wrapper">
          <AlphabetFilter
            handleClick={(e: React.MouseEvent<HTMLButtonElement>) => setFilter(e.currentTarget.value)}
            filter={filter}
          />
        </div>
        {filteredInsurers.map(({
          insurerName,
          shortDescription,
          historyDescription,
          contactDescription,
          insurerImgLink,
          contact1,
          contact2,
          contact3,
        }, index) => (
          <Insurer
            insurerName={insurerName}
            insurerImgLink={insurerImgLink}
            shortDescription={shortDescription}
            historyDescription={historyDescription}
            contactDescription={contactDescription}
            contact1={contact1}
            contact2={contact2}
            contact3={contact3}
            imageRight={!!(index % 2)}
          />
        ))}
        <section className="section section--no-results">
          <div className="wrapper">
            <button className="btn btn--secondary insurer__go-back-btn" onClick={goBackToAll}>Wróć do wszystkich</button>
          </div>
        </section>
      </Layout>
    </>
  );
};
