/* eslint-disable max-len */
import React from 'react';
import AEGON from '../../img/insurers/AEGON.png';
import AIG from '../../img/insurers/AIG.png';
import ALLIANZ from '../../img/insurers/ALLIANZ.png';
import AVIVA from '../../img/insurers/AVIVA.png';
import AXA from '../../img/insurers/AXA.png';
import BALCIA from '../../img/insurers/BALCIA.png';
import BENEFIA24 from '../../img/insurers/BENEFIA24.png';
import BEESAFE from '../../img/insurers/BEESAFE.png';
import CARDIF from '../../img/insurers/CARDIF.png';
import COMPENSA from '../../img/insurers/COMPENSA.png';
import CONCORDIA from '../../img/insurers/CONCORDIA.png';
import ELVIA from '../../img/insurers/ELVIA.png';
import TUEUROPA from '../../img/insurers/TUEUROPA.png';
import GENERALI from '../../img/insurers/GENERALI.png';
import HDI from '../../img/insurers/HDI.png';
import INTER from '../../img/insurers/INTER.png';
import INTER_RISK from '../../img/insurers/INTER_RISK.png';
import LINK4 from '../../img/insurers/LINK4.png';
import MACIF from '../../img/insurers/MACIF.png';
import METLIFE from '../../img/insurers/METLIFE.png';
import MONDIAL_ASSISTANCE from '../../img/insurers/MONDIAL_ASSISTANCE.png';
import MTU24 from '../../img/insurers/MTU24.png';
import NN from '../../img/insurers/NN.png';
import OPEN_LIFE from '../../img/insurers/OPEN_LIFE.png';
import U_POCZTOWE from '../../img/insurers/U_POCZTOWE.png';
import PREVOIR from '../../img/insurers/PREVOIR.png';
import PROAMA from '../../img/insurers/PROAMA.png';
import PRUDENTIAL from '../../img/insurers/PRUDENTIAL.png';
import PTR from '../../img/insurers/PTR.png';
import PZM from '../../img/insurers/PZM.png';
import PZU from '../../img/insurers/PZU.png';
import SIGNAL_IDUNA from '../../img/insurers/SIGNAL_IDUNA.png';
import VIENNA_LIFE from '../../img/insurers/VIENNA_LIFE.png';
import TRASTI from '../../img/insurers/TRASTI.png';
import TUW from '../../img/insurers/TUW.png';
import TUZ from '../../img/insurers/TUZ.png';
import UNIQA from '../../img/insurers/UNIQA.png';
import WARTA from '../../img/insurers/WARTA.png';
import YCD from '../../img/insurers/YCD.png';

export const insurers = [
  {
    insurerName: 'AEGON',
    insurerImgLink: AEGON,
    shortDescription: 'Grupa kapitałowa AEGON w Polsce składa się z czterech podmiotów, takich jak Towarzystwo Ubezpieczeń na Życie S.A., specjalizujące się w  tworzeniu długoterminowych programów inwestycyjno-oszczędnościowych, które zawierane są w ramach ubezpieczenia na życie,  Powszechne Towarzystwo Emerytalne S.A., zarządzające funduszem emerytalnym, Ubezpieczenia Majątkowe oraz Aegon Services Sp. z o.o. Towarzystwo ubezpieczeniowe Aegon wyróżnia się bardzo szeroką ofertą. Jego pojawienie się w Polsce otworzyło przed klientami zupełnie nowe możliwości – nie tylko w zakresie ubezpieczenia, ale też planów inwestycyjnych.',
    historyDescription: 'Historia firmy Aegon sięga 170 lat wstecz, a w Polsce działa od 20 lat – czyli od 1999 roku. Firma należ do międzynarodowej grupy kapitałowej, która działa aż w 20 krajach, między innymi w Europie, Azji czy obu Amerykach. Wieloletnia historia firmy obfitowała w różnego rodzaju zmiany – początkowo firma w Polsce funkcjonowała pod nazwą Nationative, jednak w 2005 roku została wykupiona przez holenderską grupę Aegon. Dzisiaj Aegon cieszy się dużym zaufaniem klientów na całym świecie.',
    contactDescription: 'Kontakt z firmą Aegon można nawiązać przez infolinię lub poprzez formularz na stronie internetowej.',
    contact1: 'Infolinia: 801 300 900 lub +48 22 592 10 00',
    contact3: 'Formularz kontaktowy: https://www.aegon.pl/Strona-glowna/Kontakt/Wyslij-wiadomosc/',
  },
  {
    insurerName: 'AIG',
    insurerImgLink: AIG,
    shortDescription: 'AIG to firma, której historia sięga aż 1919 roku. To właśnie wtedy w Szanghaju powstała firma American Asiatic Underwriters. Dziś AIG jest jedną z najbardziej rozpoznawalnych firm ubezpieczeniowych na świecie. Nieustannie poszerza swoją ofertę ubezpieczeniową, dzięki czemu dociera do coraz większej liczby klientów. W efekcie rozpoznawalność marki i zaufanie do niej rośnie w wielu krajach świata. AIG działa w ponad 100 krajach, co zdecydowanie wyróżnia ją spośród innych ubezpieczycieli.',
    historyDescription: 'Przez ostatnie 100 lat firma AIG ewoluowała, dzięki czemu dzisiaj posiada bardzo atrakcyjną ofertę. Ogromne grono specjalistów oraz chęć wychodzenia naprzeciw oczekiwaniom klientów sprawiła, że od momentu powstania, czyli od 1919 roku, firma mogła tak bardzo się rozwinąć. Co istotne, w Stanach Zjednoczonych AIG jest liderem w zakresie dostarczania rozwiązań ubezpieczeniowych. Spółki AIG od wielu lat są notowane na Nowojorskiej Giełdzie Papierów Wartościowych, a także na Giełdzie Papierów Wartościowych w Tokio.',
    contactDescription: 'Siedziba polskiego oddziału firmy AIG znajduje się w Warszawie przy ulicy Marszałkowskiej 11. Osoby, które chcą skontaktować się z AIG, mogą skorzystać z infolinii lub z wiadomości e-mail.',
    contact1: 'Infolinia: +48 22 528 51 00 lub +48 22 528 52 52',
    contact2: 'Adres e-mail: aig.poland@aig.com lub bok@aig.com',
  },
  {
    insurerName: 'ALLIANZ',
    insurerImgLink: ALLIANZ,
    shortDescription: 'Allianz to towarzystwo ubezpieczeniowe, na która składa się wiele spółek. W Polsce funkcjonują między innymi TU Allianz Życie, TU Allianz Polska, PTE Allianz Polska, TFI Allianz Polska, Allianz Edukacja i Allianz Polska Services. Allianz w sposób kompleksowy dba o interesy swoich klientów. Bogata oferta ubezpieczeniowa oraz umiejętne zarządzanie funduszami sprawia, że grono osób, które zaufały firmie Allianz, stale się powiększa.',
    historyDescription: 'Firma Allianz powstała w Monachium w 1890 roku. Początkowo była to niewielka, lokalna firma, oferująca ubezpieczenia komunikacyjne i powypadkowe. W czasie ponad 30-letniej działalności rozwinęła się w potężne towarzystwo ubezpieczeniowe, które dzisiaj działa na niemal całym świecie. W Polsce firma Allianz rozpoczęła działalność w marcu 1999 roku – w ciągu zaledwie kilku lat wskoczyła ona na podium największych firm ubezpieczeniowych w Polsce. Allianz cieszy się dzisiaj dużym zaufaniem Polaków i pozostaje jednym z najbardziej rozpoznawalnych towarzystw ubezpieczeniowych w kraju.',
    contactDescription: 'Aby skontaktować się z firmą Allianz Polska, należy skorzystać z formularza kontaktowego:',
    contact1: 'Infolinia: 22 422 42 24',
    contact2: 'https://www.allianz.pl/kontakt-z-allianz/?partner=SNA010',
  },
  {
    insurerName: 'AVIVA',
    insurerImgLink: AVIVA,
    shortDescription: 'AVIVA w 2021 roku została przejęta przez Allianz. AVIVA to towarzystwo ubezpieczeniowe, które posiadało bardzo szeroką ofertę dla swoich klientów. Oprócz licznych typów ubezpieczenia, mogli oni liczyć na pomoc w zakresie inwestycji. AVIVA przygotowywała także liczne produkty emerytalne, które pozwalały klientom na finansowe zabezpieczenie swojej przyszłości.',
    historyDescription: 'Firma AVIVA funkcjonuje w Polsce od 1992 roku i jest obecnie jedną z największych i najważniejszych instytucji finansowych. Firma pochodzi z Wielkiej Brytanii, gdzie działa od ponad 300 lat. Oprócz tego obsługuje klientów w wielu krajach Europy, w Azji czy w Kanadzie. Dotychczas Polacy powierzyli firmie AVIVA ponad 50 milionów swoich oszczędności – to doskonale świadczy o tym, jak wielkim zaufaniem cieszy się firma na polskim rynku.',
    contactDescription: 'Kontaktować należy się z firmą Allianz – w celu uzyskania oferty lub zgłoszenia szkody do dawnych polis AVIVA  – należy skorzystać z odpowiedniego formularza kontaktowego lub zadzwonić na infolinię:',
    contact1: 'Infolinia: 224 224 224',
    contact2: 'Formularz kontaktowy: kontakt@allianz.pl',
  },
  {
    insurerName: 'AXA',
    insurerImgLink: AXA,
    shortDescription: 'AXA to jedna z największych firm ubezpieczeniowych w Polsce i na świecie. W Polsce firma obecna jest od 2006 roku. Z oferty AXA korzysta ogromna ilość Polaków. W jej ramach działa w kraju kilka spółek, między innymi AXA Życie TU S.A., AXA Ubezpieczenia TUiR S.A., AXA PTE S.A., AXA TFI S.A. i AXA Polska S.A.',
    historyDescription: 'W październiku 2020 r. UNIQA stała się właścicielem spółek AXA w Polsce, Czechach i Słowacji, umacniając swoją pozycję na rynku. W Polsce transakcja dotyczy AXA Ubezpieczenia TUiR, AXA Życie, AXA TFI, AXA PTE i AXA Polska; nie obejmuje AXA Assistance, AXA Partners, AXA XL.',
    contactDescription: 'Aby skontaktować się z firmą AXA, należy wybrać odpowiedni formularz kontaktowy na stronie internetowej. AXA daje możliwość wysłania szybkiej wiadomości lub zamówienia rozmowy telefonicznej – wystarczy podać numer telefonu, aby konsultant skontaktował się w celu przedstawieniu oferty lub po to, aby zgłosić szkodę.',
    contact1: 'Infolinia: 22 599 95 22',
  },
  {
    insurerName: 'Balcia Insurance SE Poland',
    insurerImgLink: BALCIA,
    shortDescription: 'Balcia Insurance SE Poland to mniej znana firma ubezpieczeniowa w Polsce. Posiada jednak grono oddanych klientów, którzy cenią ją przede wszystkim za rzetelność, dobry kontakt z agentami i bardzo korzystne ceny w stosunku do innych towarzystw ubezpieczeniowych.',
    historyDescription: 'Firma Balcia Insurance SE Poland założona została w 1993 roku w Łotwie. Początkowo nazywała się BTA, jednak z czasem zmieniła nazwę i zaczęła działać w innych krajach, należących do Unii Europejskiej. Początkowo firma działała na terenie Łotwy, Litwy i Estonii. \n W 2013 roku Balcia Insurance SE Poland znalazła się na liście największych ubezpieczycieli, działających w Europie. W Polsce pojawiła się w 2009 roku, gdzie nawiązała działalność z inną firmą – RESO Europa Service. Zmiana nazwy na Początkowo firma działała na Balcia Insurance SE Poland nastąpiła w 2016 roku.',
    contactDescription: 'Aby skontaktować się z firmą Balcia Insurance SE Poland, należy skontaktować się telefonicznie lub mailowo.',
    contact1: 'Infolinia: +48 22 468 1050',
    contact2: 'E-mail: info@balcia.pl',
    contact3: 'Na stronie internetowej firmy Balcia Insurance SE Poland można również znaleźć kontakt do firmy RESO Europa Service Sp. z o.o.: +48 87 565 4444 lub likwidacja@reso.com.pl.',
  },
  {
    insurerName: 'Benefia24.pl',
    insurerImgLink: BENEFIA24,
    shortDescription: 'Firma Benefia znana jest z tanich ubezpieczeń, których zakupu można dokonać przez internet. W ofercie firmy Benefia znajdują się między innymi takie produkty, jak ubezpieczenie komunikacyjne, ubezpieczenie domu i mieszkania lub ubezpieczenie turystyczne. Benefia słynie przede wszystkim z bardzo korzystnych cen oraz wysokiej jakości obsługi. Dzięki temu posiada coraz większe grono fanów w całej Polsce.',
    historyDescription: 'Firma Benefia powstała w 1999 roku, kiedy to utworzona została spółka Benefia Towarzystwo Ubezpieczeniowe S. A. Vienna Insurance Group. W roku 2015 Benefia Majątek połączyła się z istniejącą już spółką Compensa Majątek. Co ciekawe, firma Benefia oraz zasilający ją szereg specjalistów wykreowali zupełnie nowe metody docierania do klientów, a tym samym stworzyli inne kanały sprzedaży niż te, których używano dotychczas. Dzisiaj Benefia cieszy się dużym zaufaniem klientów.',
    contactDescription: 'Aby skontaktować się z firmą Benefia, można skorzystać zarówno z infolinii, jak i z adresu e-mail:',
    contact1: 'Infolinia: +48 (22) 544 14 14. Na terenie Polski: 0 801 080 300',
    contact2: 'Biuro Obsługi Klienta +48 (22) 544 14 14',
    contact3: 'E-mail: benefia24@benefia.pl',
  },
  {
    insurerName: 'Beesafe',
    insurerImgLink: BEESAFE,
    shortDescription: 'Beesafe to internetowa marka ubezpieczeniowa należąca do Vienna Insurance Group, oferująca przede wszystkim ubezpieczenia komunikacyjne. Świat digital, nowych usług i aplikacji jest naturalnym środowiskiem dla Beesafe. Beesafe jest szybki i zwinny, ale również bardzo przyjazny i pomocny. Firma oferuje szybki kalkulator OC/AC, który umożliwia wyliczenie składki na bazie tylko trzech informacji: roku produkcji pojazdu, daty urodzenia kierowcy oraz nr rejestracyjnego auta. Oprócz obowiązkowego ubezpieczenia komunikacyjnego oraz polisy Autocaso, Beesafe daje możliwość zakupu użytecznych dodatków, jak usługa  „Wygodny Kierowca", w ramach której można liczyć na szybką naprawę pojazdu. Usługa „Wygodny Kierowca" Beesafe świadczona jest w modelu door-to-door i zapewnia m.in. naprawy techniczne i blacharskie, kontrole techniczne czy szybką wymianę opon. Nowością w ofercie firmy są „Ubezpieczenia miejskie”, przeznaczone dla osób, korzystających z takich pojazdów, jak hulajnogi, skutery, deskorolki czy rowery zwykłe i elektryczne. Ubezpieczenie miejskie Beesafe zadziała niezależnie od tego, czy osoba ubezpieczona jest właścicielem pojazdu, czy tylko go wynajmuje. Zarówno „Ubezpieczenia miejskie” jak i usługa „Wygodny kierowca” dostępne są wraz z polisą OC/AC.',
    historyDescription: 'Firma Beesafe pojawiła się na polskim mapie firm ubezpieczeniowych stosunkowo niedawno. Beesafe należy do Compensa TU SA. Obie spółki działają w ramach Vienna Insurance Group. Internetowy agent ubezpieczeniowy Beesafe jest zarejestrowany na liście agentów ubezpieczeniowych KNF pod numerem RPU 11246601/A. Beesafe działa wyłącznie w kanałach cyfrowych – polisę można zakupić na stronie internetowej beesafe.pl. Marka beesafe debiutuje w drugiej połowie 2020 roku, oferując kierowcom przede wszystkim możliwość skorzystania z usług wyłącznie online.',
    contactDescription: 'Beesafe – Kontakt',
    contact1: 'Infolinia: 500 96 56 26',
    contact2: 'E-mail: kontakt@beesafe.pl',
    contact3: 'Formularz online: https://beesafe.pl/kontakt/',
  },
  {
    insurerName: 'Cardif',
    insurerImgLink: CARDIF,
    shortDescription: 'Cardif to firma ubezpieczeniowa, będąca częścią BNP Paribas Group. W Polsce działa jako Towarzystwo Ubezpieczeń na Życie Cardif Polska S.A. i Cardif Assurances Risques Divers S.A.. Cardif uznawana jest za prekursora w dziedzinie bancassurance. Dzięki ogromnemu doświadczeniu może zajmować się wszystkimi aspektami tego rynku. ',
    historyDescription: 'Firma ubezpieczeniowa Cardif działa w Polsce od 1997 roku. Jako pierwsza w kraju wprowadziła ubezpieczenie GAP oraz ubezpieczenie na wypadek utraty pracy. W swoim okresie firma Cardif uznawana była za pioniera w zakresie sprzedaży ubezpieczeń przez swój dział telemarketingu. Z uwagi na fakt, iż Cardif ma wyjątkowo ciekawą ofertę, coraz więcej osób decyduje się na skorzystanie z usług tej firmy.',
    contactDescription: 'Aby skontaktować się z firmą Cardif, należy skorzystać z formularza kontaktowego lub zadzwonić na jeden z podanych numerów telefonu.',
    contact1: 'Infolinia: 801 801 111 lub (22) 319 00 00',
    contact2: 'Formularz kontaktowy: https://roszczenia.cardif.pl/dsa-claims/#!/claims/select',
    contact3: 'E-mail: obsluga@cardif.pl',
  },
  {
    insurerName: 'Compensa',
    insurerImgLink: COMPENSA,
    shortDescription: 'Compensa to towarzystwo ubezpieczeniowe, które cieszy się ogromnym zaufaniem klientów w Polsce. Szeroka oferta ubezpieczeniowa sprawia, że grono osób, które decydują się na skorzystanie z oferty Compensy, stale rośnie. Firma działa w Polsce od wielu lat – dzisiaj cieszy się bardzo dobrym wizerunkiem. ',
    historyDescription: 'Compensa należy do Vienna Insurance Group, lidera ubezpieczeniowego w Europie Środkowo-Wschodniej. W Polsce działają Compensa Towarzystwo Ubezpieczeń S.A. Vienna Insurance Group oraz Compensa Towarzystwo Ubezpieczeń na Życie S.A. Vienna Insurance Group. Siedziba Vienna Insurance Group znajduje się w Wiedniu, a oddziały firmy zlokalizowane są w całej Europie Środkowo-Wschodniej.',
    contactDescription: 'W celu skontaktowania się z firma Compensa w Polsce, należy wybrać najwygodniejszą dla siebie formę kontaktu. Do wyboru klientów jest infolinia lub adres e-mail.',
    contact1: 'Infolinia: +48 22 501 61 00',
    contact2: 'E-mail: centrala@compensa.pl',
    contact3: 'Zgłoszenie szkody: szkody@compensa.pl (w tytule wiadomości należy podać tytuł szkody)',
  },
  {
    insurerName: 'Concordia Ubezpieczenia',
    insurerImgLink: CONCORDIA,
    shortDescription: 'Concordia Ubezpieczenia to lider w sektorze ubezpieczeń agro. Najważniejsze wartości, które wyróżniają firmę, to przede wszystkim pewność, zaufanie i bezpieczeństwo. Dzięki temu, że Concordia wyspecjalizowała się w ubezpieczeniach agro, jej pozycja na polskim rynku ubezpieczeń jest bardzo silna.',
    historyDescription: 'Concordia Ubezpieczenia rozpoczęła swoją historię ponad 150 lat temu. Głównym akcjonariuszem firmy była Concordia Versicherungen, natomiast drugi ważny udziałowiec to Vereinigte Hagelversicherungen. Concordia dotarła do Polski ponad 20 lat temu, bo w 1997 roku. Od tego czasu rozwija się błyskawicznie, stale poszerzając grono swoich oddanych klientów. Obecnie Concordia współpracuje z ponad 2000 pośredników w całej Polsce.',
    contactDescription: 'Concordia Ubezpieczenia umożliwia różne możliwości kontaktu, w zależności od tego, która z nich jest najwygodniejsza dla klientów. Aby skontaktować się z firmą Concordia Ubezpieczenia, należy skorzystać z infolinii lub udać się do siedziby firmy, która znajduje się Warszawie przy ulicy Siennej 93/4. Zgłosić szkodę można przez odpowiedni formularz kontaktowy na stronie internetowej https://www.concordiaubezpieczenia.pl.',
    contact1: 'Infolinia: 61 85 84 800',
  },
  {
    insurerName: 'Elvia',
    insurerImgLink: ELVIA,
    shortDescription: 'Elvia to lider w branży ubezpieczeń turystycznych. Należy do międzynarodowej grupy ubezpieczeniowo-serwisowej, która specjalizuje się w ubezpieczeniach turystycznych i assistance. Choć w Polsce mniej popularna, na świecie cieszy się ogromnym zainteresowaniem. Jak podaje firma Elvia, obecnie z jej usług korzysta już 250 milionów osób, a każdej godziny pomagają 7 648 osobom.',
    historyDescription: 'Firma Elvia powstała w Szwajcarii w 1950 roku. Początkowo nosiła nazwę Elvia Travel Insurance. Założenie firmy miało miejsce w idealnym momencie – jej twórcy, którzy posiadali ogromną wiedzę o rynku ubezpieczeniowym, trafili w moment, kiedy tyl życia ludzi zaczął się zmieniać. Coraz więcej osób podróżowało, również za granicę,w  związku z czym ubezpieczenie turystyczne stało się bardzo pożądanym produktem. Od 1995 roku Elvia należy do grupy Allianz. W Polsce działa od ponad 10 lat, zatrudniając ponad 200 pracowników.',
    contactDescription: 'Aby skontaktować się z firmą Elvia w celu zapoznania się z aktualną ofertą lub zgłoszenia szkody, należy skorzystać z infolinii, formularza kontaktowego na stronie internetowej lub z adresu e-mail.',
    contact1: 'Infolinia +48 22 522 27 75',
    contact2: 'Formularz kontaktowy https://elvia.pl/kontakt',
    contact3: 'E-mail sekretariat@mondial-assistance.pl',
  },
  {
    insurerName: 'Europa Ubezpieczenia',
    insurerImgLink: TUEUROPA,
    shortDescription: 'Towarzystwo Ubezpieczeniowe Europa to firma, która swoją ofertę kieruje do najbardziej wymagających klientów. Gwarantuje ona obsługę na najwyższym poziomie – dzięki temu produkty firmy Europa Ubezpieczenia cieszą się coraz większą popularnością w całym kraju.',
    historyDescription: 'Europa Ubezpieczenia działa w Polsce już od 20 lat, nieustannie poszerzając i udoskonalając swoją ofertę. Specjalizuje się w sprzedaży ubezpieczeń we współpracy z różnego rodzaju instytucjami finansowymi, w szczególności z dużymi bankami oraz partnerami z innych branż, tworząc w ten sposób tzw. branżę affinity. Obecnie Europa Ubezpieczenia obsługuje w Polsce już kilka milionów klientów. ',
    contactDescription: 'Aby skontaktować się z firmą Europa Ubezpieczenia, należy skorzystać z jednej z dostępnych metod kontaktu.',
    contact1: 'Infolinia 801 500 300',
    contact2: 'E-mail  bok@tueuropa.pl',
    contact3: 'Formularz kontaktowy https://tueuropa.pl/zadaj-pytanie.htm, Zamówienie rozmowy https://tueuropa.pl/zamow-rozmowe.htm',
  },
  {
    insurerName: 'Generali',
    insurerImgLink: GENERALI,
    shortDescription: 'Generali to jedno z największych i najbardziej rozpoznawalnych towarzystw ubezpieczeniowych nie tylko w Polsce, ale też na całym świecie. Specyficzne logo doskonale pokrywa się z misją firmy. Jej celem jest zapewnienie pełnej ochrony oraz poczucia bezpieczeństwa. Nie bez powodu Generali cieszy się tak ogromną popularnością, a grono klientów firmy stale rośnie.',
    historyDescription: 'Firma Generali powstała w 1937 roku – co ciekawe, nastąpiło to na ziemiach polskich. Do roku 1939 firma miała swoje siedziby w większych miastach Polski, jednak później działalność prywatnych towarzystw ubezpieczeniowych została zakazana. Generali wróciło do Polski w 1998 roku i rozpoczęło szybki rozwój. Kolejne lata były dla Generali pasmem sukcesów – liczne nagrody i rekordowe wyniki sprawiły, że firma stała się jednym z największym towarzystw ubezpieczeniowych i do dzisiaj jest niekwestionowanym liderem. Oferta produktowa Generali jest bardzo szeroka. Firma oferuje różne typy polis – od komunikacyjnych, poprzez ubezpieczenia turystyczne i na życie, aż po ubezpieczenie domu i mieszkania.',
    contactDescription: 'Firma Generali przygotowała dla swoich klientów wiele możliwości kontaktu – w zależności od sprawy oraz tego, który sposób jest dla nich najbardziej wygodny: https://www.generali.pl/kontakt/kontakt.html',
    contact1: 'Infolinia: 913 913 913',
    contact2: 'E-mail: centrumklienta@generali.pl',
    contact3: 'Formularz kontaktowy do zgłoszenia szkody: https://www.generali.pl/strefa-klienta/zgloszenie-szkody.html',
  },
  {
    insurerName: 'HDI',
    insurerImgLink: HDI,
    shortDescription: 'HDI to towarzystwo ubezpieczeniowe, funkcjonujące od ponad 100 lat. Posiada bardzo bogatą tradycję i zasady, takie jak gwarancja bezpieczeństwa i pełne zaufanie. Dzięki temu klienci firmy HDI mogą cieszyć się najwyższą jakością obsługi. ',
    historyDescription: 'Firma HDI powstała ponad sto lat temu. W Polsce funkcjonuje od 2002 roku. Dzisiaj HDI to znak towarowy, pod którym funkcjonuje spółka TUiR "WARTA" S.A.. Firma oferuje ubezpieczenia zarówno dla osób prywatnych, jak i dla małych i średnich przedsiębiorstw. Przez wiele lat swojej działalności towarzystwo ubezpieczeniowe HDI zdążyło wypracować bardzo ciekawe i wygodne rozwiązania dla osób i firm, które są zainteresowane ubezpieczeniem. Harmonia, doświadczenie i innowacyjność to najważniejsze zasady, którymi dzisiaj kieruje się HDI.',
    contactDescription: 'Aby skontaktować się z firma HDI, nalezy skorzystać ze specjalnej infolinii. Więcej szczegółów na temat dostępnych ofert można uzyskać online lub kontaktując się z konsultantem. Do dyspozycji klientów, którzy chcą zgłosić szkodę, stworzony jest specjalny formularz kontaktowy. Wystarczy podać najważniejsze informacje, między innymi okoliczności i datę powstania szkody.',
    contact1: 'Infolinia 22 449 99 99 lub 801 801 801',
    contact2: 'Zgłaszanie szkody https://hdiubezpieczenia.pl/hdi-izi/#/app/policydata',
  },
  {
    insurerName: 'TU INTER Polska S.A.',
    insurerImgLink: INTER,
    shortDescription: 'TU INTER Polska S.A.  to dwie firmy ubezpieczeniowe: INTER Polska S.A. i INTER-ŻYCIE Polska S.A. Akcjonariuszem jest niemiecka grupa ubezpieczeniowa INTER, która powstała niemal 100 lat temu – a dokładnie w 1926 roku. Firma ta specjalizuje się przede wszystkim w ubezpieczeniach zdrowotnych oraz w tworzeniu zaawansowanych programów ubezpieczeniowych. ',
    historyDescription: 'TU INTER Polska S.A.  działa na polskich rynku od 1991 roku. Na początku funkcjonowała pod nazwą Inter – Fortuna, jednak w 2000 roku nazwa została zmieniona na INTER. W 2017 roku TU INTER Polska S.A.  obchodziło swoje 25-lecie działalności na polskim rynku. Przez ten czas firma zdołała wypracować nowoczesne rozwiązania, dzięki czemu dzisiaj klienci mogą cieszyć się ciekawą ofertą. Wśród produktów TU INTER Polska S.A.  znajduje się między innymi ubezpieczenie domu i mieszkania, podróży czy majątku, ale też wiele pakietów ubezpieczeniowych, dedykowanych lekarzom, pielęgniarkom czy fizjoterapeutom.',
    contactDescription: 'Aby skontaktować się z firmą TU INTER Polska S.A., należy skorzystać z infolinii lub formularza kontaktowego, który znajduje się na stronie internetowej.',
    contact1: 'Infolinia 22 333 75 28',
    contact2: 'Formularz kontaktowy https://interpolska.pl/kontakt/',
    contact3: 'Zgłoszenie szkody https://inter-direct.pl/zglos-szkode',
  },
  {
    insurerName: 'Inter Risk',
    insurerImgLink: INTER_RISK,
    shortDescription: 'Inter Risk to nowoczesne towarzystwo ubezpieczeniowe, które cieszy się bardzo dużym zaufaniem Polaków. Główne zasady, którymi kieruje się firma Inter Risk, to profesjonalizm, odpowiedzialność i specjalizacja. Specjaliści i agenci Inter Risk wykazują się bardzo dużą fachowością w obsłudze klientów, dzięki czemu ich grono rośnie z roku na rok.',
    historyDescription: 'Towarzystwo ubezpieczeniowe Inter Risk jest ściśle powiązane z Vienna Insurance Group – to główny akcjonariusz. Firma buduje swoją pozycję od ponad 190 lat. Na polskim rynku Inter Risk i Vienna Insurance Group działa od 1993 roku. To wystarczająca ilość czasu, aby wypracować rozwiązania, które doskonale odpowiadają na potrzeby polskich klientów. Szeroka oferta wyróżnia Inter Risk spośród innych firm ubezpieczeniowych.',
    contactDescription: 'Aby skontaktować się z Inter Risk, należy skorzystać z formularza zgłoszeniowego lub infolinii. Na stronie internetowej Inter Risk znajdują się również formularze do zgłoszenia szkody lub pomagające w znalezieniu najbliższego warsztatu samochodowego, współpracującego z firmą.',
    contact1: 'Infolinia 22 575 25 25',
    contact2: 'Formularz do zgłoszenia szkody https://zgloszenie.interrisk.pl/assetclaim/new',
  },
  {
    insurerName: 'Link4',
    insurerImgLink: LINK4,
    shortDescription: 'Link4 to firma ubezpieczeniowa, która prowadzi działalność na bardzo szeroką skalę. Oprócz zapewniania bezpieczeństwa swoim klientom, wspiera również liczne fundacje. Link4 oferuje przede wszystkim ubezpieczenia komunikacyjne, ale też na życie, ubezpieczenie domu i mieszkania czy turystyczne.',
    historyDescription: 'Firma Link4 działa w Polsce od 2003 roku. Oferuje ona różne kanały sprzedaży: zarówno online, jak i przez telefon i agentów. To właśnie firma Link4 jako pierwsza wprowadziła możliwość skorzystania z samochodu zastępczego w momencie, kiedy auto zostało uszkodzone na skutek stłuczki. To innowacyjne rozwiązanie zostało szybko przejęte przez inne firmy ubezpieczeniowe, aby w końcu stać się standardem.',
    contactDescription: 'Istnieje kilka możliwości kontaktu z firmą Link4. Można skorzystać z formularza online, infolinii lub udać się do siedziby firmy, która znajduje się w Warszawie przy ulicy Postępu 15.',
    contact1: 'Infolinia: 22 444 44 23 dla nowych klientów lub 22 444 44 44 dla obecnych klientów',
    contact2: 'Formularz kontaktowy  https://www.link4.pl/kontakt',
  },
  {
    insurerName: 'MACIF Życie TUW',
    insurerImgLink: MACIF,
    shortDescription: 'MACIF Życie TUW to francuska firma, która na polskim rynku zyskuje coraz większe grono klientów. Odpowiedzialność i profesjonalizm to najważniejsze zasady, którymi firma MACIF Życie TUW kieruje się w swojej działalności. Oferuje ona bardzo korzystne rozwiązania ubezpieczenia na życie.',
    historyDescription: 'Firma MACIF Życie TUW istnieje od ponad 50 lat. W tym czasie wyspecjalizowała się w ubezpieczeniach na życie, które mają na celu zagwarantowanie bezpieczeństwa klientów. Co istotne, konstrukcja ofert ubezpieczeniowych firmy MACIF Życie TUW jest bardzo klarowna i przejrzysta, dzięki czemu klienci mogą mieć do firmy 100% zaufania.',
    contactDescription: 'Siedziba firmy MACIF Życie TUW znajduje się w Warszawie przy ulicy Bema 89. Aby nawiązać kontakt z firmą, wystarczy skorzystać z którejś z poniższych możliwości:',
    contact1: 'Infolinia 22 535 02 00',
    contact2: 'Kontakt e-mail  kontakt@macif.com.pl',
  },
  {
    insurerName: 'MetLife',
    insurerImgLink: METLIFE,
    shortDescription: 'MetLife to towarzystwo ubezpieczeniowe, dla którego najważniejsze jest bycie przyjaznym i przejrzystym dla klientów. Oferta MetLife skonstruowana jest w sposób bardzo klarowny, co z pewnością jest niezwykle istotne na dzisiejszym rynku ubezpieczeniowym – nie tylko w Polsce.',
    historyDescription: 'Firma MetLife powstała w Stanach Zjednoczonych w 1868 roku. To bez wątpienia jedna z najstarszych firm ubezpieczeniowych na świecie. Mimo to wyróżnia się jednak bardzo nowoczesnym podejściem i stale się rozwija, wychodząc naprzeciw oczekiwaniom współczesnych klientów. MetLife rozpoczęło swoją działalność w Polsce w 1990 roku.',
    contactDescription: 'Aby skontaktować się z firmą MetLife, należy skorzystać z formularza, który znajduje się na stronie internetowej metlife.pl.',
    contact1: 'Infolinia: 22 523 50 70',
  },
  {
    insurerName: 'Mondial Assistance',
    insurerImgLink: MONDIAL_ASSISTANCE,
    shortDescription: 'Mondial Assistance to firma, która specjalizuje się w udzielaniu pomocy osobom, którym przydarzyła się nieoczekiwana okoliczność, na przykład stłuczka, wypadek samochodowy czy awaria auta. Odpowiednia pomoc ma duże znaczenie, dlatego Mondial Assistance to firma, która wyspecjalizowała się w jej udzielaniu.',
    historyDescription: 'Mondial Assistance to towarzystwo ubezpieczeniowe, które jest bardzo mocno związane z firmą Elvia Travel Insurance. Jest to lider ubezpieczeń turystycznych. Firma powstała w 1974 roku we Francji jako firma SANCAS Mondial Assistance, a w 2000 roku doszło do udanej fuzji firm Sancas i Elvia. Kilka lat później zarząd podjął decyzję, aby spółka funkcjonowała pod ujednoliconą nazwą Mondial Assistance. ',
    contactDescription: 'Mondial Assistance to firma, z którą można skontaktować się bardzo łatwo.',
    contact1: 'Infolinia 22 522 28 20',
    contact2: 'Zgłaszanie szkody szkody.turystyczne@mondial-assistance.pl',
  },
  {
    insurerName: 'MTU24.pl',
    insurerImgLink: MTU24,
    shortDescription: 'MTU24, czyli Moje Towarzystwo Ubezpieczeń, jest częścią grupy STU Ergo Hestia, która swoją działalność rozpoczęła w 2002 roku. MTU24 specjalizuje się przede wszystkim w sprzedaży ubezpieczeń komunikacyjnych, a także ubezpieczeń mieszkań, domów i przedsiębiorstw.',
    historyDescription: 'MTU24 funkcjonuje w Polsce od wielu lat. W tym czasie firma doskonale wyspecjalizowała się w obsłudze polskich klientów, dla których najważniejszy jest profesjonalizm towarzystwa ubezpieczeniowego oraz poczucie bezpieczeństwa. Firma MTU24 specjalizuje się przede wszystkim w ubezpieczeniach komunikacyjnych. Ilość klientów, którzy zdecydowali się na zakup OC lub AC w MTU24 stale rośnie.',
    contactDescription: 'Aby skontaktować się z firmą MTU24, należy skorzystać z jednego z formularzy kontaktowych, dostępnych na stronie internetowej https://www.mtu24.pl/kontakt/.',
    contact1: 'Infolinia: 58 555 65 00',
  },
  {
    insurerName: 'Nationale Nederlanden',
    insurerImgLink: NN,
    shortDescription: 'Nationale Nederlanden to jedno z najbardziej cenionych towarzystw ubezpieczeniowych na polskim rynku. Firma wyróżnia się bardzo wysoką jakością obsługi oraz korzystnymi cenami ubezpieczeń. Nationale Nederlanden specjalizuje się w polisach zdrowotnych, ochronie dziecka i kapitału, a także w inwestycjach.',
    historyDescription: 'Firma Nationale Nederlanden składa się z kilku spółek, między innymi Nationale-Nederlanden Towarzystwo Ubezpieczeń na Życie S.A., Nationale-Nederlanden Powszechne Towarzystwo Emerytalne S.A., Nationale-Nederlanden Towarzystwo Ubezpieczeń S.A. i Nationale-Nederlanden Usługi Finansowe Sp. z o.o.. Firma działa w Polsce od kilku lat, jednak działa również w wielu innych krajach. Bogata historia sprawia, że firma kieruje się cenionymi przez klientów wartościami, takimi jak wiarygodność i specjalizacja. Dzisiaj Nationale Nederlanden obsługuje ponad 17 milionów klientów.',
    contactDescription: 'Aby skontaktować się z konsultantami firmy Nationale Nederlanden, można skorzystać z jednej z poniższych możliwości:',
    contact1: 'Infolinia 801 20 30 40 lub +48 22 522 71 24',
    contact2: 'E-mail info@nn.pl',
  },
  {
    insurerName: 'Open Life',
    insurerImgLink: OPEN_LIFE,
    shortDescription: 'Open Life to firma, która kieruje się jedną, najważniejszą zasadą – chce zapewnić swoim klientom pełne poczucie bezpieczeństwa i zagwarantować im spokój na wypadek pojawienia się nieprzewidzianych okoliczności. Open Life współpracuje z wieloma bankami i innymi instytucjami finansowymi, ponieważ oferuje również bardzo ciekawe rozwiązania inwestycyjne i chce utrzymać je na najwyższym poziomie.',
    historyDescription: 'Open Life Towarzystwo Ubezpieczeń Życie Spółka Akcyjna (Open Life TU Życie S.A.)  działa w Polsce od 2011 roku. Licencję przejęła one  od brytyjskiego Royal & Sun Alliance. Nabycie akcji Open Life przez Getin Noble Bank S.A., Open Finance S.A. i dr. Leszka Czarneckiego miało miejsce również w 2011 roku. Open Life rozwijało sie w Polsce bardzo szybko. Już po kilku miesiącach działalności dołączyło do grona najlepszych firm ubezpieczeniowych w kraju.',
    contactDescription: 'Aby skontaktować  się z firmą Open Life, należy skorzystać z infolinii lub formularza kontaktowego.',
    contact1: 'Infolinia 801 222 333 lub +48 22 101 41 60',
    contact2: 'Formularz kontaktowy https://www.openlife.pl/kontakt/',
  },
  {
    insurerName: 'Pocztowe TUW',
    insurerImgLink: U_POCZTOWE,
    shortDescription: 'Pocztowe TUW to stosunkowo nowa firma ubezpieczeniowa, która działa w ramach Poczty Polskiej. Z uwagi na ogromne zaufanie do Poczty Polskiej oraz fakt, iż wiele osób regularnie korzysta z jej usług, wielu Polaków zdecydowało się na skorzystanie z Pocztowe TUW.',
    historyDescription: 'Pocztowe TUW powstało w Polsce od 2006 roku, kiedy to rozpoczęło sprzedaż polis dla klientów indywidualnych. Z czasem Pocztowe TUW poszerzało swoją ofertę, dodając do niej między innymi ubezpieczenia dla rolników. W 2017 roku nastąpiło zawarcie porozumienia pomiędzy Pocztą Polską a Funduszem Składkowym Ubezpieczenia Społecznego Rolników „FSUSR”, w efekcie czego ubezpieczenia dla rolników stały się filarem Pocztowego Towarzystwa Ubezpieczeń Wzajemnych (Pocztowe TUW).',
    contactDescription: 'Chcesz skontaktować się z konsultantem lub agentem firmy Pocztowe TUW ? Skorzystaj z jednej z poniższych możliwości:',
    contact1: 'Kontakt telefoniczny +48 801 00 22 88 lub +48 48 370 48 48',
    contact2: 'E-mail info@ubezpieczeniapocztowe.pl',
  },
  {
    insurerName: 'PRÉVOIR',
    insurerImgLink: PREVOIR,
    shortDescription: 'PRÉVOIR jest firma ubezpieczeniową, która została założona w 1910 roku we Francji. PRÉVOIR specjalizuje się w indywidualnych ubezpieczeniach na życie, a zakres oferty obejmuje oszczędzanie, zabezpieczenie zdrowia, kredytu i emerytury.',
    historyDescription: 'Choć od momentu powstania PRÉVOIR minęło ponad 100 lat, firma jest bardzo nowoczesna i z łatwością spędza wszelkie dzisiejsze standardy. W Polsce Grupa PRÉVOIR działa od 2000 roku, a swoją siedzibę ma aż w 16 polskich miastach. Oferta PRÉVOIR jest bardzo szeroka, a sama firma cieszy się coraz większym zaufaniem.',
    contactDescription: 'Aby skontaktować się z konsultantami firmy PRÉVOIR, należy skorzystać z infolinii lub e-maila.',
    contact1: 'Infolinia (22) 572 80 80',
    contact2: 'E-mail prevoir@prevoir.pl',
  },
  {
    insurerName: 'Proama',
    insurerImgLink: PROAMA,
    shortDescription: 'Proama to część Grupy Generali, która działa od 1831 r. w ponad 60 krajach na całym świecie. W 2011 r. zarejestrowano Groupama SA w Polsce.W ofercie Proama znaleźć można nowoczesne ubezpieczenia OC i AC,, ubezpieczenia mieszkania oraz inne produkty, jak ubezpieczenia dla małych i średnich firm oraz ubezpieczenia osobowe.',
    historyDescription: 'Proama działa w Polsce od 2011 roku. Dzisiaj firma zatrudnia około 200 agentów, a do 2015 roku zlikwidowała już 60 000 szkód. W 2013 roku Proama oficjalnie stała się częścią Grupy Generali. Dzisiaj jest to bardzo silna na rynku firma ubezpieczeniowa, która wyróżnia się najwyższą jakością obsługi. Dzięki temu grono jej klientów rośnie z dnia na dzień. Procedury w firmie Proama są podobne do tych, które doskonale znają klienci Generali.',
    contactDescription: 'Aby skontaktować się z agentem lub doradcą firmy Proama, należy skorzystać z jednej z możliwości kontaktu:',
    contact1: 'Infolinia 815 815 815 ',
    contact2: 'Formularz kontaktowy mklienta@proama.pl',
    contact3: 'Likwidacja szkód  szkody@proama.pl ',
  },
  {
    insurerName: 'Prudential',
    insurerImgLink: PRUDENTIAL,
    shortDescription: 'Prudential to firma ubezpieczeniowa, która cieszy się ogromnym zaufaniem klientów na całym świecie. Posiada ona bardzo szeroką ofertę ubezpieczeniową, w której znajdują się między innymi ubezpieczenia komunikacyjne, na życie lub ubezpieczenia domu i mieszkania.',
    historyDescription: 'Firma Prudential powstała w 1927 roku w ramach spółki zależnej Towarzystwa Ubezpieczeń Przezorność oraz angielskiej spółki akcyjnej Towarzystwo Ubezpieczeń Prudential. . Działalność firmy została wstrzymana podczas II wojny światowej i w okresie okupacji, jednak po tych wydarzeniach bardzo szybko wróciła ona do swojej działalności. Dzisiaj Prudential to jedna z najbardziej rozpoznawalnych firm ubezpieczeniowych w Polsce.',
    contactDescription: 'Osoby, które chcą skontaktować się z firmą Prudential, mogą skorzystać z infolinii lub formularza kontaktowego na stronie internetowej prudential.pl.',
    contact1: 'Infolinia 801 30 20 10 lub +48 22 548 06 98',
  },
  {
    insurerName: 'PTR Polskie Towarzystwo Reasekuracji',
    insurerImgLink: PTR,
    shortDescription: 'PTR Polskie Towarzystwo Reasekuracji to jedyna w Polsce firma, która prowadzi wyłącznie działalność reasekuracyjną, polegającą na ubezpieczaniu innych firm ubezpieczeniowych poprzez przejmowanie od nich części składki w zamian za udział w wypłacie odszkodowania.',
    historyDescription: 'PTR Polskie Towarzystwo Reasekuracji działa w Polsce od 1996 roku. Od tego czasu firma wspiera działalność innych towarzystw ubezpieczeniowych, prowadząc działalność reasekuracyjną. Oprócz działalności w Polsce, PTR Polskie Towarzystwo Reasekuracji funkcjonuje również w innych krajach w Europie Środkowo-Wschodniej. Od stycznia 2009 roku akcjonariuszem firmy PTR Polskie Towarzystwo Reasekuracji jest Fairfax Financial Holdings Limited z siedzibą w Toronto.',
    contactDescription: 'Aby skontaktować się z firmą PTR Polskie Towarzystwo Reasekuracji, należy skorzystać z jednej z dostępnych opcji kontaktu. Siedziba firmy znajduje się w Warszawie przy ulicy Bytomskiej 4.',
    contact1: 'Telefon (+48) 22 832 02 56 lub (+48) 667 505 069',
    contact2: 'E-mail info@polishre.com',
  },
  {
    insurerName: 'PZM Polski Związek Motorowy',
    insurerImgLink: PZM,
    shortDescription: 'PZM Polski Związek Motorowy jest związkiem, który działa na podstawie ustawy z dnia 25 czerwca 2010 r. o sporcie (tj. Dz.U z 2018 r.poz. 1263) zrzeszającym największych fanów motoryzacji. Oprócz poszerzania wiedzy na temat motoryzacji, związek świadczy również usługi z zakresu Assistance oraz sprzedaży pakietów ubezpieczeniowych.',
    historyDescription: 'PZM Polski Związek Motorowy skupia się przede wszystkim na sporcie, jednak realizuje również szereg misji społecznych. Pierwszy program Assistance PZM Polski Związek Motorowy  uruchomił już w 1993 r. Również od 1993 r. usługi są całkowicie zorganizowane na zasadach rynkowych. Dzisiaj PZM Polski Związek Motorowy  oferuje wiele usług, dzięki czemu wszystkie osoby zainteresowane motoryzacją znajdą coś dla siebie.',
    contactDescription: 'W celu nawiązania kontaktu z firmą PZM Polski Związek Motorowy, należy skorzystać z jednej z poniższych opcji:',
    contact1: 'Infolinia  + 48 22 532 84 44',
    contact2: 'Assistance 24h, pomoc drogowa + 48 22 532 84 44',
    contact3: 'Rzeczoznawcy + 48 22 385 85 85',
  },
  {
    insurerName: 'PZU',
    insurerImgLink: PZU,
    shortDescription: 'PZU, czyli Powszechny Zakład Ubezpieczeń to spółka, której historia sięga aż 1803 roku. Dzisiaj jest jedną z najprężniej działających firm ubezpieczeniowych w Polsce, a wielu Polaków nieprzerwanie, od lat korzysta z oferty ubezpieczeniowej PZU. Nowoczesna oferta i wysoka jakość obsługi, a także ogromne zaufanie Polaków, którym cieszy się PXU – wszystko to sprawia, że to jedno z najważniejszych towarzystw ubezpieczeniowych w kraju.',
    historyDescription: 'Historia PZU rozpoczęła się w 1803 roku. Działalność rozpoczęła w 1921 roku. Pakiet kontrolny PZU jest w posiadaniu Skarbu Państwa z przewagą kapitału polskiego. W 2006 r. firma PZU osiągnęła rekordowy wynik finansowy wynoszący aż 3,7 mld zł. W ofercie PZU znajduje się ponad 200 produktów – tak szeroka oferta niezmiennie przyciąga miliony Polaków.',
    contactDescription: 'W celu skontaktowania się z doradcami lub konsultantami PZU, możesz skorzystać z jednej z poniższych możliwości:',
    contact1: 'Infolinia 801 102 102 lub 22 566 55 55',
    contact2: 'E-mail: kontakt@pzu.pl',
  },
  {
    insurerName: 'Signal Iduna',
    insurerImgLink: SIGNAL_IDUNA,
    shortDescription: 'Signal Iduna to istniejąca od wielu lat niemiecka firma kapitałowa, która na początku swojej działalności współpracowała przede wszystkim z właścicielami firm i rzemieślnikami. Dziś Signal Iduna ma ponad 100 lat tradycji. Firma specjalizuje się przede wszystkim w sprzedaży ubezpieczeń na życie oraz ubezpieczeń domu i mieszkania.',
    historyDescription: 'Signal Iduna to obecnie jedna z najpotężniejszych instytucji finansowo-ubezpieczeniowych, działających na rynku europejskim. Obecnie na sukces grupy pracuje 16 towarzystw ubezpieczeniowych i cztery spółki finansowe w Niemczech. Signal Iduna może pochwalić się szybkim rozwojem w krajach takich jak Polska, Rumunia, Węgry czy Szwajcaria. ',
    contactDescription: 'Chcesz skontaktować się z firmą Signal Iduna? Wykorzystaj jedną z oferowanych przez firmę możliwości kontaktu:',
    contact1: 'Infolinia 801 120 120 lub +48 22 505 65 06',
    contact2: 'E-mail info@signal-iduna.pl',
  },
  {
    insurerName: 'Skandia – Vienna Life',
    insurerImgLink: VIENNA_LIFE,
    shortDescription: 'Skandia – Vienna Life to wyspecjalizowana instytucja finansowa, która zajmuje się przede wszystkim sprzedażą produktów ubezpieczeniowych, inwestycyjnych i oszczędnościowych. W swojej ofercie posiada wiele interesujących usług, na przykład inPlus, Fund Alert, Investbox czy Rebalancing.',
    historyDescription: 'Działająca pod nazwą Skandia firma ubezpieczeniowa zmieniła w 2014 r. nazwę na Vienna Life po włączeniu Vienna Insurance Group. Dzisiaj Vienna Life stanowi część dużej grupy Vienna Insurance Group, która działa już 25 krajach, a jej historia sięga 1824 r. ',
    contactDescription: 'Aby nawiązać kontakt z doradcami lub konsultantami firmy Skandia – Vienna Life, należy skorzystać z infolinii lub dostępnego na stronie www adresu e-mail.',
    contact1: 'Infolinia 801 888 000 lub 22 460 22 22',
    contact2: 'E-mail info@viennalife.pl',
  },
  {
    insurerName: 'TUW Towarzystwo Ubezpieczeń Wzajemnych',
    insurerImgLink: TUW,
    shortDescription: 'Towarzystwo Ubezpieczeń Wzajemnych, w skrócie TUW, to firma, która jest bardzo silnie związana z polskimi tradycjami ubezpieczeniowymi. TUW skupia się przede wszystkim na klientach kościelnych, rolniczych i samorządowych. ',
    historyDescription: 'Towarzystwo Ubezpieczeń Wzajemnych działa od 1992 roku. Podobnie jak inne towarzystwa ubezpieczeniowe w Polsce, działa pod nadzorem Komisji Nadzoru Finansowego. Dzisiaj Towarzystwo Ubezpieczeń Wzajemnych cieszy się dobrą pozycją na polskim rynku ubezpieczeniowych, a ilość Polaków, którzy decydują się na skorzystanie z oferty TUW, rośnie z roku na rok.',
    contactDescription: 'Aby skontaktować się z firmą Towarzystwo Ubezpieczeń Wzajemnych, można skorzystać z bezpłatnej infolinii.',
    contact1: 'Infolinia 801 001 003 lub 22 545 39 50',
    contact2: 'Zgłaszanie szkody zgloszenie.szkody@tuw.pl',
  },
  {
    insurerName: 'Trasti',
    insurerImgLink: TRASTI,
    shortDescription: [
      <p>
        Trasti to nowa marka, która pojawiła się na polskim rynku ubezpieczeń. Trasti specjalizuje się przede wszystkim w sprzedaży ubezpieczeń komunikacyjnych, a podstawą działalności jest hasło:
        <i> Trasti! Po prostu fair!</i>
      </p>,
      <h3>Ubezpieczenia komunikacyjne od Trasti</h3>,
      <p>Podstawą oferty Trasti jest ubezpieczenie komunikacyjne OC. Klienci mogą liczyć na bardzo atrakcyjne ceny, a także na nowoczesne podejście do obsługi klienta. Tym, co wyróżnia Trasti na rynku, jest możliwość wygodnego zakupu ubezpieczenia – całkowicie online, bez wychodzenia z domu, z wykorzystaniem bezpiecznych i nowoczesnych metod płatności.</p>,
      <p>Wszystkie produkty ubezpieczeniowe są bardzo proste, dzięki czemu są w pełni zrozumiałe dla klientów. Oprócz ubezpieczenia OC, w Trasti można zakupić również ubezpieczenie Assistance, NNW, a także można dostać Zieloną Kartę. Interesujące jest podejście Trasti do polis NNW oraz Assistance ponieważ ubezpieczyciel umożliwia Klientom bardzo rozbudowaną konfigurację tych produktów. Takie podejście zapewnia dopasowaną ofertę dla każdego - niezależnie czy pokonujemy duże dystanse autostradowe, czy też używamy auta lokalnie, blisko domu. Obecnie Trasti współpracuje ze 170 warsztatami na terenie całej Polski, aby zapewnić wygodną i jak najlepszą obsługę napraw pojazdów. To bardzo ważny aspekt, na który Trasti zwraca ogromną uwagę</p>,
    ],
    historyDescription: 'Ubezpieczycielem, który wspiera Trasti i dba o bezpieczeństwo osób, które zdecydowały się na zakup ubezpieczenia w tej firmie, jest Zavarovalnica Triglav. Jest to z całą pewnością jeden z najprężniej działających i najbardziej rozpoznawalnych graczy na rynku ubezpieczeń w Europie Południowej. \n' +
      '\n' +
      'Trasti jest marką, która powstała stosunkowo niedawno. Warto jednak wiedzieć, że firma stworzona została przez zespół ekspertów, którzy mają wieloletnie doświadczenie na rynku ubezpieczeniowym, szczególnie w zakresie ubezpieczeń komunikacyjnych. Gwarantuje to wysoką jakość obsługi oraz znajomość aktualnych trendów ubezpieczeniowych w Polsce i Europie. \n',
    contactDescription: 'Aby skontaktować się z firmą Trasti, może skorzystać z jednej z poniższych metod: ',
    contact1: 'Infolinia: +48 22 250 93 73',
    contact2: 'E-mail: kontakt@trasti.pl',
    contact3: 'Formularz online: https://trasti.pl/pl/kontakt',
  },
  {
    insurerName: 'TUZ',
    insurerImgLink: TUZ,
    shortDescription: 'TUZ Ubezpieczenia posiada w swojej ofercie wiele usług ubezpieczeniowych, między innymi ubezpieczenia komunikacyjne, dla domu i rodziny, ubezpieczenia biznesowe i rolnicze. ',
    historyDescription: 'TUZ Ubezpieczenia działa od początku września 2003 roku. Od tego czasu firma zbudowała swoją pozycję na polskim rynku, a dziś cieszy się sporym gronem klientów. Specjalizacja, zaufanie i profesjonalizm to jedne z najważniejszych wartości, które wyróżniają firmę spośród innych towarzystw ubezpieczeniowych.',
    contactDescription: 'TUZ Ubezpieczenia ma swoją główną siedzibę w Warszawie przy ulicy Bokserskiej 66. Aby skontaktować się z firmą TUZ Ubezpieczenia, można skorzystać z którejś z poniższych możliwości:',
    contact1: 'Infolinia 22 327 60 60',
    contact2: 'E-mail centrala@tuz.pl',
  },
  {
    insurerName: 'UNIQA',
    insurerImgLink: UNIQA,
    shortDescription: ['Austriacka UNIQA ma 200 lat tradycji ubezpieczeniowej w Europie. To jedna z najsilniejszych marek ubezpieczeniowych w Austrii i jedna z wiodących grup ubezpieczeniowych w Europie Środkowej i Wschodniej. Działa w 18 krajach Europy Środkowej i Wschodniej i obsługuje 15 mln klientów.',
      (
        <ul style={{ listStylePosition: 'inside', marginTop: 10 }}>
          <li>Wystawiła klientom 25 mln polis w 2020 roku</li>
          <li>Zebrała składki o wartości 5,6 mld euro w 2020 roku</li>
          <li>Zarządzała aktywami o wartości 32 mld euro w 2020 roku</li>
          <li>Ma ocenę ratingową A- od agencji Standard & Poor’s</li>
        </ul>
      ),
      <br />,
      'W 2000 roku weszła na polski rynek najpierw kupując ZUiR Polonia, towarzystwo ubezpieczeniowe, które wówczas działało już od 10 lat, a następnie TU Filar. W 2020 roku Grupa UNIQA stała się właścicielem spółek AXA, a dokładnie AXA Ubezpieczenia TUiR S.A., AXA Życie TU S.A., AXA Polska S.A., AXA TFI S.A. i AXA PTE S.A. W kwietniu 2021 roku nastąpiło połączenie spółek ubezpieczeniowych UNIQA i AXA. I od tego momentu działają jako UNIQA.', 'UNIQA posiada bogatą tradycję, a dzięki dużej liczbie specjalistów, którzy pracują na jej sukces, może pochwalić się dobrymi wynikami. '],
    historyDescription: 'Austriacka UNIQA ma 200 lat tradycji ubezpieczeniowej w Europie. To jedna z najsilniejszych marek ubezpieczeniowych w Austrii i jedna z wiodących grup ubezpieczeniowych w Europie Środkowej i Wschodniej. Działa w 18 krajach Europy Środkowej i Wschodniej i obsługuje 15 mln klientów.',
    contactDescription: 'Aby skontaktować się z firmą UNIQA, należy skorzystać z infolinii: 22 599 95 22 ',
  },
  {
    insurerName: 'WARTA',
    insurerImgLink: WARTA,
    shortDescription: 'Towarzystwo ubezpieczeniowe WARTA to jedna z najstarszych i najbardziej cenionych firm w Polsce. Misją i najważniejszą wartością firmy jest wszechstronne nastawienie na potrzeby klientów, otwarta komunikacja i zaufanie, a także nastawienie na efektywność i wyniki.',
    historyDescription: 'Firma WARTA powstała w latach 20-tych. Powstało wtedy Towarzystwo Reasekuracyjne WARTA S.A. w Poznaniu. Założyciele Towarzystwa to: Krajowe Ubezpieczenie Ogniowe, Krajowe Ubezpieczenie na Życie, Poznański Bank Ziemian oraz Związek Producentów Rolnych. W kolejnych latach nastąpił gwałtowny rozwój firmy WARTA, a dzisiaj jest jedną z najbardziej rozpoznawalnych instytucji ubezpieczeniowych. W roku 2000 nowym udziałowcem WARTY zostaje grupa KBC, a w  roku 2012 nowymi wspólnikami WARTY zostały niemiecka firma Grupa Talanx i japoński ubezpieczyciel Meiji Yasuda.',
    contactDescription: 'Aby nawiązać kontakt z doradcą, pracującym w firmie WARTA, można skorzystać z jednej z dostępnych możliwości:',
    contact1: 'Infolinia i zgłaszanie szkody 502 308 308',
  },
  {
    insurerName: 'YouCanDrive',
    insurerImgLink: YCD,
    shortDescription: 'YouCanDrive specjalizuje się przede wszystkim w sprzedaży ubezpieczeń komunikacyjnych, mieszkaniowych i polis na życie. Oferta YouCanDrive skierowana jest przede wszystkim do młodych osób – przed 30 rokiem życia – które szukają stabilizacji i poczucia bezpieczeństwa, a także chcą zadbać o swoich najbliższych. ',
    historyDescription: 'YouCanDrive jest częścią Grupy Ergo Hestia. Działa w Polsce już od wielu lat, nieustannie poszerzając grono swoich klientów. Osoby, które chcą skorzystać z usług YouCanDrive, mogą liczyć przede wszystkim na wysoką jakość obsługi oraz na nowoczesną, dostosowaną do potrzeb młodych klientów ofertę. ',
    contactDescription: 'Aby nawiązać kontakt z firmą YouCanDrive, można skorzystać z jednej z dostępnych opcji:',
    contact1: 'Formularz kontaktowy https://www.youcandrive.pl/kontakt/',
    contact2: 'Zgłaszanie szkody https://zgloszenieszkody.ergohestia.pl/#/ihestia/wizard/step1?brand=ycd',
  },
];
