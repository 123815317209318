import React from 'react';
import classnames from 'classnames';
import { DetailsItem } from './DetailsItem';
import phone2 from '../../img/icons/icon-phone2.svg';

interface IInsurer {
  insurerName: string;
  shortDescription: string;
  insurerImgLink?: string;
  contact1?: string;
  contact2?: string;
  contact3?: string;
  contactDescription: string;
  historyDescription: string;
  imageRight?: boolean;
}

export const Insurer: React.FC<IInsurer> = ({
  insurerName,
  insurerImgLink,
  shortDescription,
  historyDescription,
  imageRight,
  contact1,
  contact2,
  contact3,
  contactDescription,
}) => {
  const rowStyles = classnames('row row--gutters-large', {
    'row--row-reverse': imageRight,
  });

  return (
    <section className="section section--insurer">
      <div className="wrapper">
        <div className={rowStyles}>
          <div className="row__large-6">
            <div className="image-wrapper">
              <img src={insurerImgLink || '/insurers/item.png'} alt={`${insurerName} - logo`} />
            </div>
          </div>
          <div className="row__large-6">
            <span className="heading-short heading-short--no-padding">Ubezpieczyciel</span>
            <h2 className="heading">{insurerName}</h2>
            <p className="paragraph">{shortDescription}</p>
            <div className="insurer">
              <DetailsItem itemTitle="Historia firmy">
                <p className="insurer__description">{historyDescription}</p>
              </DetailsItem>
              <DetailsItem itemTitle="Kontakt">
                <p className="insurer__description">{contactDescription}</p>
                {contact1 &&
                  <div className="insurer__contact-number-wrapper">
                    <img
                      className="insurer__contact-number-img"
                      src={phone2}
                      alt="Phone"
                    />
                    <span className="insurer__contact-number">{contact1}</span>
                  </div>}
                {contact2 && <div className="insurer__contact-number">{contact2}</div>}
                {contact3 && <div className="insurer__contact-number">{contact3}</div>}
              </DetailsItem>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
