import React from 'react';

const alphabetString = 'abceghilmnopstuwy';

export const AlphabetFilter: React.FC<{ handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void; filter: string }> = ({ handleClick, filter }) => (
  <>
    <h3 className="alphabet-filter__title">Przeglądaj alfabetycznie…</h3>
    <div className="alphabet-filter">
      {alphabetString.split('').map(letter => (
        <button
          onClick={handleClick}
          className={`alphabet-filter__letter ${letter === filter ? 'alphabet-filter__letter--active' : ''}`}
          value={letter}
        >
          {letter}
        </button>
      ))}
    </div>
  </>
);
