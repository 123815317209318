import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import { useOnClickOutside } from '../useOnClickOutside';

export const DetailsItem: React.FC<{ itemTitle: string }> = ({ itemTitle, children }) => {
  const insurerItemRef = useRef(null);
  const [isOpen, setOpened] = useState(false);

  const toggle = () => setOpened(!isOpen);
  const close = () => setOpened(false);

  useOnClickOutside(insurerItemRef, close);

  const itemContainerStyles = classnames('insurer__container', {
    'insurer__container--open': isOpen,
  });

  const itemStyles = classnames('insurer__item', {
    'insurer__item--open': isOpen,
  });

  const toggleStyles = classnames('insurer__toggle-title', {
    'insurer__toggle-title--open': isOpen,
  });

  return (
    <div ref={insurerItemRef} className={itemContainerStyles}>
      <button className={toggleStyles} onClick={toggle}>{itemTitle}</button>
      <div className={itemStyles}>
        {children}
      </div>
    </div>
  );
};
